.nav-link-input {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 3px;
    padding: 16px 22px;
    margin: 4px 0 4px 0;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 22px;
    font-weight: normal;
    color: #000000;
    cursor: pointer;
    a {
        color: #000000;
    }
    // label {
    //     display: inline-block;
    //     line-height: 32px;
    //     font-size: 11px;
    //     font-weight: bold;
    //     color: #a1a1a1;
    //     padding: 0 22px;
    //     width: 132px;
    // }
    svg {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 0;
        display: inline-block;
        cursor: pointer;
    }
}