.page-header {
  padding: 0 0px 22px 0px;
  margin-right: 0px;
  margin-left: 0px;
  .col:first-child {
    padding-left: 0;
  }
  .col:last-child {
    padding-right: 0;
  }
  .page-header-actions {
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    padding: 22px 0 0 0;
    a,
    > div {
      min-width: 120px;
      color: #a1a1a1;
      cursor: pointer;
      margin: 0 24px 0 0;
      &:last-child {
        margin-right: 0;
      }
      svg {
        width: 14px;
        height: 14px;
        display: inline-block;
        margin: 0 12px 3px 0;
        fill: #a1a1a1;
        color: #a1a1a1;
      }
      p {
        display: inline-block;
      }
    }
  }
  .actions {
    > div,
    div.action {
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      margin-left: 32px;
    }
    svg {
      width: 16px;
      height: 16px;
      margin: 0 12px 3px 0;
      display: inline-block;
      cursor: pointer;
      &.edit,
      &.save {
        fill: #009842;
        color: #009842;
      }
      &.cancel {
        fill: #e44;
        color: #e44;
      }
    }
    div:disabled,
    div.disabled,
    div[disabled] {
      cursor: not-allowed;
      text-decoration: none;
      pointer-events: none;
      color: #a1a1a1;
      svg {
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
  }
  &.page-header-line {
    border-bottom: solid 1px #e6e6e6;
  }
  .page-title {
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 22px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .page-subtitle {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 30px;
    font-weight: normal;
    margin: 16px 0 0 0;
  }
  .delete,
  .edit,
  .success {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0px;
    font-weight: normal;
    color: #a1a1a1;
    cursor: pointer;
    margin: 0 24px 0 0;
    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin: 0 12px 3px 0;
      fill: #a1a1a1;
      color: #a1a1a1;
    }
    &.disabled {
      cursor: not-allowed;
      text-decoration: none;
      pointer-events: none;
      color: #a1a1a1;
      svg {
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
  }
  .success {
    color: #009842;
    svg {
      fill: #009842;
      color: #009842;
    }
  }
}

.page-content {
  &.editing {
    .input-row {
      color: #a1a1a1;
      > div > div:not(.editing) {
        color: #a1a1a1;
        opacity: 0.2;
        pointer-events: none;
        input,
        label {
          color: #a1a1a1;
        }
        .actions {
          display: none;
        }
      }
    }
  }
}

.container-inner.fixed-header {
  position: relative;
  padding: 108px;
  .page-header {
    position: absolute;
    top: 32px;
  }
}
