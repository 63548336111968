.App {
  height: 100%;
  // min-height: 812px;
  // .container {
  //     height: 100%;
  // }
}

.app-loader {
  height: 100vh;
  width: 100vw;
  > div {
    left: calc(50vw - 40px);
    position: absolute;
    top: calc(50vh - 40px);
  }
}

:focus {
  outline: none !important;
}

.col-0-15 {
  -webkit-flex: 0 0 1.2499995%;
  flex: 0 0 1.2499995%;
  max-width: 1.2499995%;
}

.col-0-25 {
  -webkit-flex: 0 0 2.0833325%;
  flex: 0 0 2.0833325%;
  max-width: 2.0833325%;
}

.col-0-5 {
  -webkit-flex: 0 0 4.166665%;
  flex: 0 0 4.166665%;
  max-width: 4.166665%;
}

.col-0-75 {
  -webkit-flex: 0 0 6.2499975%;
  flex: 0 0 6.2499975%;
  max-width: 6.2499975%;
}

.col-1-25 {
  -webkit-flex: 0 0 7.08333%;
  flex: 0 0 7.08333%;
  max-width: 7.08333%;
}


.col-1-5 {
  -webkit-flex: 0 0 12.499995%;
  flex: 0 0 12.499995%;
  max-width: 12.499995%;
}

.col-2-5 {
  -webkit-flex: 0 0 20.833325%;
  flex: 0 0 20.833325%;
  max-width: 20.833325%;
}

.h-300px {
  height: 325px;
}

@media (min-width: 768px) {
  .col-md-0-25 {
    -webkit-flex: 0 0 2.0833325%;
    flex: 0 0 2.0833325%;
    max-width: 2.0833325%;
  }
  
  .col-md-0-5 {
    -webkit-flex: 0 0 4.166665%;
    flex: 0 0 4.166665%;
    max-width: 4.166665%;
  }
  
  .col-md-0-75 {
    -webkit-flex: 0 0 6.2499975%;
    flex: 0 0 6.2499975%;
    max-width: 6.2499975%;
  }
  
  .col-md-1-5 {
    -webkit-flex: 0 0 12.499995%;
    flex: 0 0 12.499995%;
    max-width: 12.499995%;
  }
  
  .col-md-2-5 {
    -webkit-flex: 0 0 20.833325%;
    flex: 0 0 20.833325%;
    max-width: 20.833325%;
  }
}

@media (min-width: 992px) {
  .col-lg-0-25 {
    -webkit-flex: 0 0 2.0833325%;
    flex: 0 0 2.0833325%;
    max-width: 2.0833325%;
  }
  
  .col-lg-0-5 {
    -webkit-flex: 0 0 4.166665%;
    flex: 0 0 4.166665%;
    max-width: 4.166665%;
  }
  
  .col-lg-0-75 {
    -webkit-flex: 0 0 6.2499975%;
    flex: 0 0 6.2499975%;
    max-width: 6.2499975%;
  }
  
  .col-lg-1-5 {
    -webkit-flex: 0 0 12.499995%;
    flex: 0 0 12.499995%;
    max-width: 12.499995%;
  }
  
  .col-lg-2-5 {
    -webkit-flex: 0 0 20.833325%;
    flex: 0 0 20.833325%;
    max-width: 20.833325%;
  }
}

@media (min-width: 1200px) {
  .col-xl-0-25 {
    -webkit-flex: 0 0 2.0833325%;
    flex: 0 0 2.0833325%;
    max-width: 2.0833325%;
  }
  
  .col-xl-0-5 {
    -webkit-flex: 0 0 4.166665%;
    flex: 0 0 4.166665%;
    max-width: 4.166665%;
  }
  
  .col-xl-0-75 {
    -webkit-flex: 0 0 6.2499975%;
    flex: 0 0 6.2499975%;
    max-width: 6.2499975%;
  }
  
  .col-xl-1-5 {
    -webkit-flex: 0 0 12.499995%;
    flex: 0 0 12.499995%;
    max-width: 12.499995%;
  }
  
  .col-xl-2-5 {
    -webkit-flex: 0 0 20.833325%;
    flex: 0 0 20.833325%;
    max-width: 20.833325%;
  }
}

.table-divider {
  padding: 36px 0 14px 0px;
  font-size: 11px;
  letter-spacing: 0px;
  text-transform: uppercase;
  line-height: 22px;
  font-weight: bold;
  color: #009842;
}

.input-row {
  min-height: 72px;
}