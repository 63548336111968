.loading-modal {
    .ant-modal-footer {
        border: 0;
        text-align: center;
        padding-bottom: 28px;
    }
    span {
        width: 100%;
        text-align: center;
    }
    .ant-modal-body {
        overflow-y: scroll;
        max-height: 400px;
    }
}

.ant-modal-mask {
    z-index: 1031;
}

.ant-modal-wrap {
    z-index: 1035;
}
