.rel-settings-row {
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
    &.invalid {
        border-color: #f00;
    }
}

.rel.container-inner {
    h3 {
        margin-top: 32px;
        margin-bottom: 8px;
    }
    .info {
        color: #a1a1a1;
        font-size: 12px;
        margin-bottom: 22px;
    }
}
