.select-input {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 3px;
    padding: 16px 0;
    margin: 4px 0 4px 0;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 22px;
    font-weight: normal;
    color: #000000;
    label {
        display: inline-block;
        line-height: 32px;
        font-size: 11px;
        font-weight: bold;
        color: #a1a1a1;
        padding: 0 22px;
        width: 124px;
        &.invalid {
            color: #e44;
        }
    }
    select {
        display: inline-block;
        height: 32px;
        width: 100%;
        max-width: calc(100% - 250px);
        border: 0 !important;
        background: #fff;
        outline: none;
        padding: 0;
        &:disabled,
        &[disabled] {
            color: #000000;
            border: 0;
            background: #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
        }
    }
    .actions {
        position: absolute;
        right: 16px;
        top: 21px;
        display: inline-block;
        height: 24px;
        svg {
            width: 16px;
            height: 16px;
            margin: 0 12px;
            cursor: pointer;
            display: inline-block;
            &.edit,
            &.save {
                fill: #009842;
                color: #009842;
            }
            &:disabled,
            &[disabled] {
                cursor: not-allowed;
                // opacity: 0.5;
                fill: #a1a1a1;
                color: #a1a1a1;
                text-decoration: none;
                pointer-events: none;
            }
            &.cancel {
                fill: #e44;
                color: #e44;
            }
        }
    }
}