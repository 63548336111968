.page-navigation {
    // margin: 22px 0;
    margin-bottom: 22px;
    .last-changed {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0px;
        font-weight: normal;
        color: #A1A1A1;
    }
    .page-navigation-back {
        display: inline-block;
        color: #A1A1A1;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 20px;
        font-weight: normal;
        margin: 0 32px 0 0;
        transform: translateY(1px);
        cursor: pointer;
        svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin: 0 6px 4px 0;
            fill: #A1A1A1;
            color: #A1A1A1;
        }
        span {
            display: inline-block;
            vertical-align: bottom;
            margin-bottom: 1px;
        }
    }
}