.modal {
  display: block;
  position: relative;
  .modal-dialog {
    margin: 0;
    max-width: 99999px;
    .modal-content {
      border: 0;
      .modal-body {
        min-height: 140px;
      }
      .scroll {
        max-height: 250px;
        overflow-y: scroll;
      }
      .row {
        margin-top: 11px;
        margin-bottom: 11px;
        select,
        input[type="text"] {
          width: 100%;
          max-width: 286px;
        }
        input[type="text"] {
          border-bottom: 1px solid #000 !important;
        }
        input[type="checkbox"] {
          width: 18px;
          width: 18px;
          max-width: 286px;
        }
      }
    }
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    cursor: not-allowed;
    text-decoration: none;
    pointer-events: none;
  }
}
