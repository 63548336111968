.error {

  h1 {
    font-weight: 700;
    -webkit-text-fill-color: #e6e6e6;
    font-size: 300px;
    letter-spacing: 0px;
    line-height: 1.2;
  }

  .button {
    padding: 11px 22px;
    user-select: none;
    background-color: #fff;
    border-radius: 3px;
    overflow: visible;
    width: 155px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px 0px;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.2;
    font-weight: 400;
  }

  .plug {
    display: block;
    width: 100%;
    max-width: 1122px;
    margin: -270px auto 0 auto;

  }

  .plug-gd {
    display: block;
    width: 100%;
    max-width: 1122px;
    margin: -270px auto 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -moz-linear-gradient(left, rgba(242,242,242,1) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(242,242,242,1) 100%);
    background: -webkit-linear-gradient(left, rgba(242,242,242,1) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 90%,rgba(242,242,242,1) 100%);
    background: linear-gradient(to right, rgba(242,242,242,1) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 90%,rgba(242,242,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#f2f2f2',GradientType=1 );

  }

}