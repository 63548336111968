$transition: all 0.1s;

.trigger:not(.disabled) {
    cursor: pointer;
}
.locations-planed {
    // .filter-button {
    //     border: 1px solid #dedede;
    //     background: transparent;
    //     border-radius: 3px;
    //     overflow: hidden;
    //     // display: block;
    //     color: #dedede;
    //     font-size: 12px;
    //     line-height: 14px;
    //     font-weight: 400;
    //     letter-spacing: 0px;
    //     text-align: left;
    //     display: inline-block;
    //     align-items: flex-start;
    //     cursor: pointer;
    //     padding: 7px 15px;
    //     margin: 0 9px;
    //     transition: $transition;
    //     svg {
    //         // display: none;
    //         transition: $transition;
    //         width: 0px;
    //         height: 9px;
    //         display: inline-block;
    //         margin: 0;
    //         path {
    //             fill: #009842;
    //             color: #009842;
    //         }
    //     }
    //     &.filter-active {
    //         color: #009842;
    //         background-color: #ffffff;
    //         border-radius: 3px;
    //         border: 0;
    //         svg {
    //             width: 9px;
    //             display: inline-block;
    //             margin: 0 0 1px 8px;
    //         }
    //     }
    // }
    .page-header-actions{
        .ant-btn-link {
            color: #a1a1a1;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            padding: 0px 15px;
            height: 28px
        }
    }
}
