@import "~react-grid-layout/css/styles.css";
@import "~csshake/scss/tools";

.react-grid-item.react-grid-placeholder {
  background: #a1a1a1;
  border-radius: 3px;
}

.dashboard {
  .react-grid-layout {
    margin: 0 -44px;
  }
  .datepicker .trigger {
    width: 184px;
    margin: 0 auto;
    display: block;
  }
  .box {
    &.resizing {
      border: 1px solid #009842;
      .box-content {
        filter: blur(1.5rem);
        position: absolute;
      }
    }
    &.box-editing {
      filter: blur(3px);
    }
    transition: filter 0.4s ease;
    background: #fff;
    border-radius: 3px;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 22px;
    // padding: 24px;
    font-weight: normal;
    color: #000;
    // height: 204px;
    height: 100%;
    width: 100%;
    // &.double {
    //   height: 816px;
    // }
    .box-content {
      height: calc(100% - 30px);
      max-height: calc(100% - 30px);
      // position: absolute;
      // overflow: hidden;
      width: 100%;
      > .row {
        margin-right: -24px;
        margin-left: -24px;
        height: 100%;
      }
      .col {
        border-left: 1px solid #f2f2f2;
        padding-right: 24px;
        padding-left: 24px;
        max-height: 100%;
        &:first-child {
          border-left: 0;
        }
        svg {
          max-height: 90%;
          height: 90%;
        }
      }
    }
    .box-header {
      // padding: 0 0px 22px 0px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 0;
      &.has-title {
        padding-bottom: 12px;
        border-bottom: 1px solid #f2f2f2;
      }
      .col {
        padding: 0;
      }
      .box-edit {
        color: #a1a1a1;
        font-size: 10px;
        font-weight: 200;
        letter-spacing: 0px;
        line-height: 16px;
      }
      .box-title {
        display: inline-block;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 16px;
      }
      svg {
        cursor: pointer;
        width: 14px;
        height: 14px;
        display: inline-block;
        fill: #a1a1a1;
        color: #a1a1a1;
        &.delete path {
          fill: #ff4d4d;
          color: #ff4d4d;
        }
      }
    }
    &.box-new {
      background: transparent;
      border: 2px dashed #a1a1a1;
      position: relative;
      svg {
        width: 28px;
        height: 28px;
        display: inline-block;
        position: absolute;
        left: calc(50% - 14px);
        top: calc(50% - 14px);
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
    .vega-embed {
      padding-right: 0;
      summary {
        display: none;
      }
    }
  }
}
