header {
  .no-click {
    // cursor: not-allowed;
    // text-decoration: none;
    // pointer-events: none;
  }

  .dropdown {
    min-width: 170px;
    width: 170px;
    max-width: 170px;
  }

  .dropdown-menu {
    top: 51px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
    border-top: 2px solid #009842;
    padding: 0;
    min-width: 260px;
    width: 260px;
    max-width: 260px;
    .dropdown-item {
      padding: 12px 12px 12px 22px;
      font-weight: bold;
      color: #86cba4;

      &.active,
      &:hover {
        color: #009842;
        background-color: #0000;

        .nav-icon {
          fill: #009842;
          color: #009842;
        }
      }

      &:disabled,
      &[disabled] {
        color: #dbdbdb;
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
        pointer-events: none;
        .nav-icon {
          fill: #dbdbdb;
          color: #dbdbdb;
        }
      }

      span {
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: -1px;
        transition: color 0.22s ease 0s;
      }

      .nav-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin: 0 16px 0 0;
        fill: #86cba4;
        color: #86cba4;
      }

      &.divider {
        padding: 12px 88px 12x 22px;
        hr {
          margin: 0;
          border-top: 1px solid #c3ead2;
        }
      }
    }
  }

  .navbar {
    border-bottom: #dbdbdb 1px solid;
    padding-bottom: 0;

    .navbar-nav {
      width: 100%;
      margin: 0 auto;
      justify-content: start;
      > li a {
        min-width: 170px;
        width: 170px;
        max-width: 170px;
      }
    }

    > a:first-child,
    > a:last-child {
      width: 135px;
    }

    .nav-item {
      &.nav-item-level-0 {
        margin: 0px 28px;
        padding: 0 12px;
        font-weight: bold;
        .nav-link {
          text-align: center;
        }
      }

      &.nav-item-level-1 {
        margin: 0px 28px 0px 0px;
        padding: 0 12px;
        font-size: 14px;
        &:first-child {
          padding-top: 12px;
        }
        font-weight: bold;
        .nav-link {
          text-align: left;
        }
      }

      .nav-link {
        color: #86cba4;
        padding-bottom: 16px;

        border-bottom: rgba(1, 1, 1, 0) 2px solid;

        &:disabled,
        &[disabled] {
          color: #dbdbdb;
          cursor: not-allowed;
          opacity: 0.5;
          text-decoration: none;
          pointer-events: none;
          .nav-icon {
            fill: #dbdbdb;
            color: #dbdbdb;
          }
        }

        span {
          display: inline-block;
          vertical-align: bottom;
          margin-bottom: 1px;
          transition: color 0.22s ease 0s;
          &.mbn1 {
            margin-bottom: -1px;
          }
        }

        .nav-icon {
          width: 27px;
          height: 27px;
          display: inline-block;
          margin: 0 16px 0 0;
          fill: #86cba4;
          color: #86cba4;
          transition: color 0.22s ease 0s;
          &.logout {
            width: 19px;
            height: 19px;
          }
        }
      }

      .nav-link {
        &.active,
        &:hover {
          color: #009842;
          .nav-icon {
            fill: #009842;
            color: #009842;
          }
        }
      }

      &.nav-item-level-0 {
        > .nav-link {
          &.active,
          &:hover {
            border-bottom: 2px solid #009842;
          }
        }
      }
    }
  }

  .nav-link.no-border {
    border: none !important;
  }

  .navbar-brand {
    font-weight: bold;
    margin-bottom: 12px;
    margin-right: 65px;
  }
}
