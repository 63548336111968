/* Begin Bootstrap */

$theme-colors: ("primary": #009842, "light": #ffffff, );
$body-bg: #F2F2F2;
$font-family-sans-serif: "Trebuchet MS",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";
$spacer: 32px;
$grid-gutter-width: 72px;
// Navbar
$navbar-height: 84px;
$navbar-default-color: #86cba4;
$navbar-default-bg: #ffffff;
$navbar-default-border: #DBDBDB;
$navbar-brand-font-size: 1.58rem;
// Dropdown
$dropdown-border-radius: 0;
$dropdown-border-width: 0;

/* End Bootstrap */