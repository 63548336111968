.update-many-modal {
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 2rem;
    // max-width: 50%;
    // .flex {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    form {
        .input-row {
            border-bottom: #e8e8e8 solid 1px;
            padding-bottom: 14px;
        }

        .direct-input {
            label {
                padding-left: 0;
            }

            input {
                border-bottom-width: 1px !important;
                border-color: #e8e8e8 !important;
                border-style: solid !important;
            }
        }

        .ant-switch {
            margin-left: 8rem;
        }
    }
}

// .ant-modal-confirm-content {
//     margin: 0 !important;
//     display: flex;
//     max-height: 70vh;
// }
