.datepicker {
  .trigger {
    &:not(.disabled) {
      cursor: pointer;
    }
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 22px;
    font-weight: bold;
    color: #009842;
    svg {
      margin: 3px 8px;
      width: 12px;
      height: 12px;
      display: inline-block;
      fill: #009842;
      color: #009842;
    }
  }
  &.tooltip-container {
    z-index: 99;
    background-color: #ffffff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
    width: 402px;
    .tooltip-arrow {
      &:before {
        border-color: #0000 #0000 #009842 #0000;
        top: 0px;
      }
      &:after {
        border-color: #0000 #0000 #009842 #0000;
      }
    }
    .bar {
      background: #009842;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      display: block;
      width: 100%;
      height: auto;
      padding: 7px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline;
          padding: 2px 0;
          margin: 0 9px;
          cursor: pointer;
          &.active {
            border-bottom: 1px solid #ffffff;
          }
        }
      }
    }
    .inner {
      display: block;
      // padding: 30px 6px 12px 6px;
      padding: 24px 6px 12px 6px;
      .row {
        margin: 0;
      }
      .select-month {
        color: #000000;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0px;
        margin: 0 0 28px 0;
        span {
          &::selection {
            color: #000000;
          }
          display: inline-block;
          cursor: pointer;
          margin: 0px 8px;
          svg {
            margin: 0px 4px 3px 4px;
            width: 12px;
            height: 12px;
            display: inline-block;
            fill: #000000;
            color: #000000;
          }
          // &.calender-toolbar-month {
          //     width: 150px;
          //     min-width: 150px;
          //     max-width: 150px;
          // }
        }
      }
      .select-date {
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
        overflow: visible;
        background-color: transparent;
        color: #dedede;
        border: 1px solid #dedede;
        margin: 0 8px 16px 8px;
        padding: 7px 10px;
        max-width: 62px;
        &.active {
          background-color: #009842;
          color: #fff;
          cursor: normal;
          pointer-events: none;
        }
        &.disabled {
          cursor: normal;
          pointer-events: none;
          color: #f2f2f2;
          border: 1px solid #f2f2f2;
        }
      }
      .time {
        border-top: 1px solid #f2f2f2;
        margin: 0 14px 0 14px;
        padding-top: 8px;
        .col-12,
        .direct-input label {
          padding-left: 0;
        }
        .direct-input {
          padding: 0px;
          label {
            width: 50px;
          }
        }
      }
    }
    .footer {
      display: block;
      border-top: 1px solid #f2f2f2;
      margin: 0 14px 0 14px;
      padding: 16px 0px 16px 0px;
      button {
        // display: block;
        border: 0;
        background-color: #009842;
        border-radius: 5px;
        box-shadow: 6px 5px 20px 0px rgba(140, 140, 140, 0.25);
        overflow: visible;
        // width: 100%;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        padding: 8px 18px;
        text-align: center;
        line-height: 14px;
        float: right;
        // background-color: #009842;
        // border-radius: 3px;
        // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);
        // overflow: visible;
        // color: #ffffff;
        // font-size: 12px;
        // font-weight: 400;
        // letter-spacing: 0px;
        // line-height: 14px;
        // padding: 8px 18px;
        // float: right;
        svg {
          margin: 0 9px 1px 0;
          width: 12px;
          height: 12px;
          display: inline-block;
          fill: #ffffff;
          color: #ffffff;
        }
      }
    }
    .rc-calendar {
      width: 100%;
      height: 100%;
      border: 0;
      box-shadow: none;
      font-family: inherit;
      .rc-calendar-month-panel-body,
      .rc-calendar-year-panel-body {
        padding: 0;
      }
      .rc-calendar-month-panel-header,
      .rc-calendar-year-panel-header {
        // flex-basis: 0;
        // flex-grow: 1;
        max-width: 100%;
        color: #000;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0px;
        margin: 0 0 28px 0;
        width: 100%;
        padding-right: 36px;
        padding-left: 36px;
        line-height: 18px;
        border: 0;
        .rc-calendar-month-panel-year-select,
        .rc-calendar-year-panel-year-select {
          width: auto;
          display: inline-block;
          cursor: pointer;
          margin: 0px 8px;
          position: relative;
        }
        a {
          display: inline-block;
          cursor: pointer;
          margin: 0px 8px;
          padding: 0;
          line-height: 18px;
          position: relative;
          &:hover {
            background-color: transparent;
          }
        }
      }
      .rc-calendar-month-panel-month {
        width: auto;
        line-height: 18px;
        &:hover {
          background-color: transparent !important;
        }
        .select-date {
          margin: 0 auto 16px auto;
        }
      }
      .rc-calendar-year-panel-year {
        border-radius: 3px;
        height: 34px;
        line-height: 20px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
        overflow: visible;
        background-color: transparent;
        color: #dedede;
        border: 1px solid #dedede;
        margin: 9px auto;
        padding: 7px 10px;
        max-width: 62px;
        min-width: 62px;
        width: 62px;
      }
      .rc-calendar-month-panel-month {
        .select-date {
          border: 1px solid #dedede;
        }
      }
      .rc-calendar-year-panel-last-decade-cell,
      .rc-calendar-year-panel-next-decade-cell {
        .rc-calendar-year-panel-year {
          border: 1px dashed #dedede;
        }
      }
      .rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
        background-color: #009842;
        color: #fff;
        cursor: normal;
        pointer-events: none;
      }
      .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
        background-color: transparent;
        .select-date,
        .rc-calendar-year-panel-year {
          background-color: #009842;
          color: #fff;
          cursor: normal;
          pointer-events: none;
        }
      }
    }
  }
}

.rc-calendar-selected-day .rc-calendar-date,
.rc-calendar-date.rc-calendar-selected-day {
  background: #009842 !important;
  color: #fff !important;
  cursor: normal;
  pointer-events: none;
}

// .rc-calendar-tbody tr td {
//   background: #009842 !important;
// }

// .rc-calendar-table {
//   border-collapse: separate;
// }
// .rc-calendar-table td {
//   border: solid 1px #000;
// }
// .rc-calendar-tbody tr td:first-child:has(.rc-calendar-selected-day) {
//   border-top-left-radius: 10px;
// }
// .rc-calendar-tbody tr td:last-child:has(.rc-calendar-selected-day) {
//   border-top-right-radius: 10px;
// }
// .rc-calendar-tbody tr td:first-child:has(.rc-calendar-selected-day) {
//   border-bottom-left-radius: 10px;
// }
// .rc-calendar-tbody tr td:last-child:has(.rc-calendar-selected-day) {
//   border-bottom-right-radius: 10px;
// }

.rc-calendar-month-panel-prev-year-btn,
.rc-calendar-month-panel-next-year-btn,
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn,
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  margin: 6px 12px 3px 12px;
  width: 12px;
  height: 12px;
  display: inline-block;
  fill: #000000;
  color: #000000;
  padding: 0 !important;
}

.rc-calendar-month-panel-prev-year-btn,
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-prev-month-btn,
.rc-calendar-prev-year-btn {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' xml:space='preserve'%3E%3Cpath d='M128.6,239.2c-8.9,9.4-8.9,24.1,0,33.5L348,504.4c4.8,5.1,11.2,7.6,17.7,7.6c6.2,0,12.2-2.4,16.8-6.7 c9.8-9.3,10.2-24.7,0.9-34.5L179.9,256L383.4,41.1c9.3-9.8,8.9-25.2-0.9-34.5c-9.8-9.2-25.2-8.8-34.5,1L128.6,239.2L128.6,239.2z'%3E%3C/path%3E%3C/svg%3E");
}

.rc-calendar-month-panel-next-year-btn,
.rc-calendar-year-panel-next-decade-btn,
.rc-calendar-next-month-btn,
.rc-calendar-next-year-btn {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.009 512.009'%3E%3Cpath d='M383.404 239.234L163.975 7.615c-9.24-9.801-24.674-10.167-34.45-.951-9.777 9.265-10.191 24.698-.926 34.475l203.508 214.845-203.509 214.87c-9.265 9.777-8.85 25.21.927 34.475a24.263 24.263 0 0 0 16.75 6.68c6.461 0 12.922-2.56 17.701-7.631l219.429-231.619c8.922-9.387 8.922-24.138-.001-33.525z'%3E%3C/path%3E%3C/svg%3E");
}

.rc-calendar-month-select,
.rc-calendar-year-select {
  transform: translateY(-5px);
}

.rc-calendar-week-number-cell {
  font-weight: bold;
}
