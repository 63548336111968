.add-location-modal {
    .modal-header {
        .row {
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;
            .col {
                padding: 0 !important;
                margin: 0 !important;
                &.foresighted-months {
                    input {
                        display: inline-block;
                        border: 0;
                        border-bottom: #000 solid 1px;
                        background: transparent;
                        font-size: 12px;
                        line-height: 12px;
                        width: 84px;
                    }
                }
            }
        }
    }
    .modal-content {
        height: 700px;
        .modal-body {
            background-color: #f2f2f2;
            .row {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .table-footer {
        display: none;
    }
    .tasks,
    .skills {
        display: flex;
        max-width: 300px;
        .task,
        .skill {
            flex-grow: 1;
            margin: 0 2px 0 2px;
            &:first-child {
                margin: 0 2px 0 0px;
            }
            &:last-child {
                margin: 0 0px 0 2px;
            }
            color: #a1a1a1;
            &.task-done {
                color: #000;
            }
            &.skill-required {
                color: #000;
            }
        }
        .task.col,
        .skill.col {
            padding-right: 0 !important;
            padding-left: 0 !important;
            overflow: hidden !important;
            min-width: 28px !important;
            max-width: 28px !important;
            font-size: 11px !important;
            letter-spacing: -1px;
            margin: 0;
        }
    }
    .tasks,
    .skills {
        margin-right: 0px;
        margin-left: 0px;
        justify-content: left;
    }
    .due-later {
        color: #a1a1a1;
    }
    .due-warning {
        &.due-warning-1 {
            color: #e5b800;
        }
        &.due-warning-2 {
            color: #ffaa22;
        }
        &.due-warning-3 {
            color: #ee4444;
        }
    }
    .due-unnecessary {
        // color: #e6e6e6 !important;
        visibility: hidden;
    }
    .due-fixed {
        // color: #e6e6e6 !important;
        visibility: hidden;
    }
    &.tooltip-container {
        width: 174px;
        height: 107px;
        background-color: #ffffff;
        border-radius: 3px;
        border: 0;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        overflow: visible;
        padding: 12px 14px;
        p {
            color: #000000;
            font-size: 12px;
            font-weight: 400;
            margin: 8px 6px;
            letter-spacing: 0px;
            line-height: 14px;
            span {
                color: #009842;
                font-weight: 700;
                min-width: 50px;
                display: inline-block;
            }
            &.title {
                margin: 9px 6px 22px 6px;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
            }
        }
        .delete,
        .save {
            cursor: pointer;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1.2;
            text-align: right;
            svg {
                width: 14px;
                height: 14px;
                display: inline-block;
                margin: 0 10px 3px 0;
            }
        }
        .delete {
            color: #a1a1a1;
            svg {
                fill: #a1a1a1;
                color: #a1a1a1;
            }
        }
        .save {
            color: #009842;
            svg {
                fill: #009842;
                color: #009842;
            }
        }
        .close {
            margin: 0;
            text-align: right;
            cursor: pointer;
            svg {
                width: 14px;
                height: 14px;
                display: inline-block;
                margin: 0 -5px 3px 0;
                fill: #a1a1a1;
                color: #a1a1a1;
            }
        }
    }
    .table .table-row .table-row-inner .col {
        padding-right: 12px;
        padding-left: 12px;
    }

    .col-trip {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .col.col-allNextJobDate a span {
        color: #004298 !important;
    }

    .col.col-duePrice a span,
    .col.col-operatingExpense a span {
        display: block;
        width: 100%;
        color: #a1a1a1;
        &.hidden {
            display: none;
        }
        &.highlight {
            color: #cda223 !important;
        }
        &.warning {
            color: rgb(159, 90, 253) !important;
        }
        &.danger {
            color: rgb(255, 77, 77) !important;
        }
    }
}
