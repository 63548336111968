$transition: all 0.1s;

.filter-datepicker {
  display: inline-block;
}
.filter.tooltip-container {
  max-height: 205px;
  overflow-y: scroll;
  padding: 0;
  .tooltip-body {
    background-color: #ffffff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  .tooltip-arrow {
    &:before {
      border-color: #0000 #0000 #009842 #0000;
      top: 0px;
    }
    &:after {
      border-color: #0000 #0000 #009842 #0000;
    }
  }
  .filter-option {
    padding: 10px 8px;
    margin: 0 6px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:last-child {
      border-bottom: 0;
    }

    .check {
      margin: 0px 27px 0px 0;
      width: 18px;
      height: 18px;
      background: transparent;
      border-radius: 5px;
      border: 1px #b3b3b3 solid;
      display: inline-block;
      svg {
        width: 12px;
        height: 12px;
        display: block;
        opacity: 0;
        margin: 3px;
        path {
          fill: #ffffff;
          color: #ffffff;
        }
      }
      &:hover {
        cursor: pointer;
        background: #009942;
        border: 0;
        svg {
          opacity: 1;
        }
      }
    }
    input[type="checkbox"] {
      visibility: hidden;
      display: none;
      &:checked + .check {
        background: #009942;
        border: 0;
        svg {
          opacity: 1;
        }
      }
    }
  }
}

.filter-button {
  border: 1px solid #dedede;
  background: transparent;
  border-radius: 3px;
  overflow: hidden;
  color: #dedede;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  align-items: flex-start;
  cursor: pointer;
  padding: 7px 15px;
  margin: 0 9px;
  min-width: 94px;
  transition: $transition;
  &.no-padding {
    position: relative;
    top: -2px;
    padding: 0;
    border: 0;
  }
  .filter-button-inner {
    display: inline-block;
    width: calc(100% - 14px);
    svg {
      height: 18px;
      width: 18px;
      float: left;
      path {
        fill: #009842;
        color: #009842;
      }
    }
    span {
      display: inline-block;
      padding: 0 8px;
      color: #009842;
      line-height: 18px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 22px);
    }
  }
  svg {
    // display: none;
    transition: $transition;
    width: 0px;
    height: 9px;
    display: inline-block;
    margin: 0;
    path {
      fill: #009842;
      color: #009842;
    }
  }
  &.filter-active {
    color: #009842;
    background-color: #ffffff;
    border-radius: 3px;
    border: 0;
    svg {
      width: 9px;
      display: inline-block;
      margin: 0 0 1px 4px;
    }
  }
}
