.center-loader {
  position: absolute;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  margin-top: 20px;
}

// .table .col,
// .table-footer .col {
//   border: 1px solid red;
// }

.table-footer {
  .col-1-25 {
    flex: 0 0 10.416% !important;
    max-width: 10.416% !important;
  }
}

.table {
  .table-header-col {
    svg {
      display: inline-block;
      height: 14px;
      width: 14px;
      position: relative;
      top: 0px;
      left: 4px;
      color: #a1a1a1;
      path {
        color: #a1a1a1;
        fill: #a1a1a1;
      }
    }
  }
  .col.ReactVirtualized__Table__rowColumn {
  }
  .table-header {
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
    color: #a1a1a1;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 8px 0;
    .col,
    .col div {
      overflow: visible;
      white-space: nowrap;
    }
    .col {
      padding-right: 6px;
      padding-left: 2px;
      overflow: hidden;
      min-width: 40px;
      &:first-child {
        padding-left: 12px;
      }
      &:last-child {
        padding-right: 20px;
      }
      &:nth-child(2) {
        padding-left: 12px;
        max-height: 47px;
      }
      .filter-active {
        // background: #009942;
        color: #009942;
        svg {
          color: #009942;
          fill: #009942;
          path {
            color: #009942;
            fill: #009942;
          }
        }
      }
      .can-sort {
        cursor: pointer;
        &.is-sort:after {
          display: inline-block;
          height: 14px;
          width: 14px;
          position: relative;
          top: 2px;
          left: 2px;
          color: #a1a1a1;
          svg path {
            color: #a1a1a1;
            fill: #a1a1a1;
          }
        }
        .sort-arrow {
          margin-right: 4px;
        }
        &.is-sort:not(.is-desc) .sort-arrow:after {
          content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M412.6 227.1L278.6 89c-5.8-6-13.7-9-22.4-9h-.4c-8.7 0-16.6 3-22.4 9l-134 138.1c-12.5 12-12.5 31.3 0 43.2 12.5 11.9 32.7 11.9 45.2 0l79.4-83v214c0 16.9 14.3 30.6 32 30.6 18 0 32-13.7 32-30.6v-214l79.4 83c12.5 11.9 32.7 11.9 45.2 0s12.5-31.2 0-43.2z"/></svg>');
          display: inline-block;
          height: 14px;
          width: 14px;
          position: relative;
          top: 2px;
          left: 2px;
          color: #a1a1a1;
        }
        &.is-sort.is-desc .sort-arrow:after {
          content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M99.4 284.9l134 138.1c5.8 6 13.7 9 22.4 9h.4c8.7 0 16.6-3 22.4-9l134-138.1c12.5-12 12.5-31.3 0-43.2-12.5-11.9-32.7-11.9-45.2 0l-79.4 83v-214c0-16.9-14.3-30.6-32-30.6-18 0-32 13.7-32 30.6v214l-79.4-83c-12.5-11.9-32.7-11.9-45.2 0s-12.5 31.2 0 43.2z"/></svg>');
          display: inline-block;
          height: 14px;
          width: 14px;
          position: relative;
          top: 2px;
          left: 2px;
          color: #a1a1a1;
        }
      }
      .table-selection {
        overflow: visible;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.readOnly {
          pointer-events: none !important;
          .check {
            pointer-events: none !important;
          }
        }
        .check {
          margin: 0;
          width: 18px;
          height: 18px;
          background: transparent;
          border-radius: 5px;
          border: 1px #b3b3b3 solid;
          display: inline-block;
          svg {
            width: 12px;
            height: 12px;
            display: block;
            opacity: 0;
            margin: 3px;
            left: 1px;
            top: 0;
            path {
              fill: #ffffff;
              color: #ffffff;
            }
          }
          &.disabled {
            background: #e6e6e6;
          }
          &:hover:not(.disabled) {
            cursor: pointer;
            background: #009942;
            border: 0;
            svg {
              opacity: 1;
            }
          }
        }
        input[type="checkbox"] {
          visibility: hidden;
          display: none;
          &:checked + .check {
            background: #009942;
            border: 0;
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .table-row {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 22px;
    font-weight: normal;
    color: #000000;
    a {
      color: #000000;
    }
    svg.comment {
      height: 18px;
      width: 18px;
      margin: auto;
      path,
      g {
        fill: #a1a1a1;
        color: #a1a1a1;
      }
      &:hover {
        path,
        g {
          fill: #009842;
          color: #009842;
        }
      }
    }
    .table-row-inner-extension {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      background: #fbfbfb;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      height: 100%;
      max-height: 38px;
      line-height: 38px;
      &:last-child {
        margin: 0 0 9px 0;
      }
      .table-selection {
        overflow: visible;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 40px;
        &.readOnly {
          pointer-events: none !important;
          .check {
            pointer-events: none !important;
          }
        }
        .check {
          margin: 0px 27px 0px 0;
          width: 18px;
          height: 18px;
          background: transparent;
          border-radius: 5px;
          border: 1px #b3b3b3 solid;
          display: inline-block;
          svg {
            width: 12px;
            height: 12px;
            display: block;
            opacity: 0;
            margin: 3px;
            path {
              fill: #ffffff;
              color: #ffffff;
            }
          }
          &.disabled {
            background: #e6e6e6;
          }
          &:hover:not(.disabled) {
            cursor: pointer;
            background: #009942;
            border: 0;
            svg {
              opacity: 1;
            }
          }
        }
        input[type="checkbox"] {
          visibility: hidden;
          display: none;
          &:checked + .check {
            background: #009942;
            border: 0;
            svg {
              opacity: 1;
            }
          }
        }
      }
      .table-row-inner-extension-f1 {
        position: relative;
        z-index: 9999;
        height: 100%;
        background: transparent;
        overflow: visible;
        .table-row-inner-extension-f1-l {
          z-index: 9999;
          background: transparent;
          &::before {
            content: "";
            width: 1px;
            height: 38px;
            position: absolute;
            background-color: #e2e2e2;
            z-index: 9999;
            bottom: 18px;
            left: 20px;
            overflow: visible;
          }
          &::after {
            content: "";
            width: 12px;
            height: 1px;
            position: absolute;
            background-color: #e2e2e2;
            z-index: 9999;
            bottom: 18px;
            left: 20px;
            overflow: visible;
          }
        }
      }
    }
    .table-row-inner {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0;
      margin: 9px 0;
      background: #ffffff;
      border-radius: 3px;
      height: 100%;
      max-height: 85px;
      &.has-extensions {
        margin-bottom: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        &::before {
          content: "";
          width: 1px;
          height: 44px;
          position: absolute;
          background-color: #e2e2e2;
          z-index: 9999;
          top: 52px;
          left: 20px;
        }
      }
      .col {
        padding-right: 4px;
        padding-left: 4px;
        overflow: hidden;
        min-width: 40px;
        max-height: 44px;
        text-overflow: ellipsis;
        &:first-child {
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 10px;
        }
        &:nth-child(2) {
          padding-left: 10px;
        }
        .table-selection {
          overflow: visible;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &.readOnly {
            pointer-events: none !important;
            .check {
              pointer-events: none !important;
            }
          }
          .check {
            margin: 0px 27px 0px 0;
            width: 18px;
            height: 18px;
            background: transparent;
            border-radius: 5px;
            border: 1px #b3b3b3 solid;
            display: inline-block;
            svg {
              width: 12px;
              height: 12px;
              display: block;
              opacity: 0;
              margin: 3px;
              path {
                fill: #ffffff;
                color: #ffffff;
              }
            }
            &.disabled {
              background: #e6e6e6;
            }
            &:hover:not(.disabled) {
              cursor: pointer;
              background: #009942;
              border: 0;
              svg {
                opacity: 1;
              }
            }
          }
          input[type="checkbox"] {
            visibility: hidden;
            display: none;
            &:checked + .check {
              background: #009942;
              border: 0;
              svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.table-footer {
  margin-top: 0px;
  // position: fixed;
  // bottom: 4px;
  position: relative;
  bottom: 18px;
  z-index: 999;
  transform: translateZ(100px);
  font-size: 14px;
  &.table-footer-bg {
    background: #e6e6e6;
  }
  padding: 8px 0;
  .table-row {
    margin: 0;
  }
  .col {
    // border: 1px solid red;
    padding-right: 5px;
    padding-left: 4px;
    overflow: hidden;
    min-width: 40px;
    &:first-child {
      padding-left: 12px;
    }
    &:last-child {
      padding-right: 20px;
    }
    &:nth-child(2) {
      padding-left: 10px;
      max-height: 47px;
    }
  }

  .table-header-col {
    overflow: visible;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.readOnly {
      pointer-events: none !important;
      .check {
        pointer-events: none !important;
      }
    }
  }
}

// .ReactVirtualized__Table__rowColumn {
//   border: 1px solid red;
// }
