.technician.container-inner {
    height: calc(100%);
    padding: 32px 32px 0 32px;
    .page-content {
      height: calc(100% - 92px);
    }
    .map {
        height: 100%;
    }
    .input-link {
        position: relative;
        width: 100%;
        background: #ffffff;
        border-radius: 3px;
        padding: 16px 0;
        margin: 4px 0 4px 0;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 22px;
        font-weight: normal;
        color: #000000;
        svg {
            width: 12px;
            height: 12px;
            display: inline-block;
            margin: 0 10px 0 0;
            fill: #000000;
            color: #000000;
        }
    }
}