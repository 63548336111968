.settings.container-inner {
  @import "~react-simple-tree-menu/dist/main.css";

  .tree {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .m-node.placeholder {
    border: 1px dashed #1385e5;
  }

  .m-node .inner {
    color: #a1a1a1;
    font-size: 12px;
  }

  .m-node .node {
    display: inline-block;
    width: 100%;
    padding: 4px 5px;
  }

  .m-node .node.is-active {
    color: #009842;
  }

  .button {
    display: block;
    border: 0;
    background-color: #009842;
    border-radius: 5px;
    box-shadow: 6px 5px 20px 0px rgba(140, 140, 140, 0.25);
    overflow: visible;
    width: 100%;
    max-width: 150px;
    float: right;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 13px 0;
    text-align: center;
    line-height: 18px;
    &:disabled,
    &.disabled {
      pointer-events: none;
      cursor: default;
      background-color: #a1a1a1 !important;
    }
    &.alert {
      background-color: #ffaa22;
    }
  }
}
