.upload-input {
  .hidden {
    display: none !important;
  }

  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 3px;
  padding: 16px 0;
  margin: 4px 0 4px 0;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 22px;
  font-weight: normal;
  color: #000000;
  opacity: 1;
  label {
    display: inline-block;
    line-height: 32px;
    font-size: 11px;
    font-weight: bold;
    color: #a1a1a1;
    padding: 0 22px;
    // width: 88px;
    width: 132px;
    &.invalid {
      color: #e44;
    }
  }
  input {
    display: inline-block;
    background: #ffffff;
    height: 32px;
    width: 100%;
    max-width: calc(100% - 232px);
    border: 0 !important;
    outline: none;
    padding: 0;
    &:disabled,
    &[disabled] {
      color: #000000;
    }
  }
  .actions {
    position: absolute;
    right: 16px;
    top: 21px;
    display: inline-block;
    height: 24px;
    svg,
    label {
      width: 16px;
      height: 16px;
      margin: 0 12px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        margin: 0;
        display: block;
      }
      &.edit,
      &.save {
        fill: #009842;
        color: #009842;
      }
      &:disabled,
      &[disabled] {
        cursor: not-allowed;
        // opacity: 0.5;
        fill: #a1a1a1;
        color: #a1a1a1;
        text-decoration: none;
        pointer-events: none;
      }
      &.cancel {
        fill: #e44;
        color: #e44;
      }
    }
  }
}

.direct-input {
  input {
    max-width: calc(100% - 132px);
    color: #000000;
  }
}
