.as-logo {
  opacity: .5;
  transition: opacity .6s;
  &:hover {
    opacity: 1;
  }
  // img {
    display: block;
    margin: 0 -75px;
    width: 150px;
    height: 55px;
    position: absolute;
    left: 50%;
    bottom: 24px;
  // }
  img {
    height: 100%;
  }
}

.form-signin {
  width: 100%;
  max-width: 515px;
  padding: 15px;
  margin: auto;

  .error {
    background-color: #f0f0f0;
    border-radius: 2px;
    border-color: #f0f0f0;
    padding: .375rem .75rem;
    font-size: 18px;
    background: #ff8a8a !important;
    color: #444 !important;
  }

  .logo {
    display: block;
    height: 80px;
    margin: 0 auto 30px auto;
  }

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  .form-control:focus {
    z-index: 2;
  }

  .input-group {
    margin: 9px 0;
    border-radius: 2px;
    &.invalid {
      border: 1px solid red;
    }
  }

  #buttonSubmit {
    background-color: #009842;
    border-color: #009842;
    border-radius: 2px;
    overflow: visible;
    margin: 39px 0;
  }

  button {
    height: auto;
  }

  #buttonSubmit:disabled {
    background-color: #f0f0f0 !important;
    border-color: #f0f0f0 !important;
  }

  input {
    background-color: #F0F0F0;
    border-radius: 2px;
    border-color: #F0F0F0;
    height: 58px !important;
  }

  .input-group-text {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    border-radius: 2px;
    svg {
      width: 29px;
      height: 29px;
      fill: #B3B3B3;
    }
  }

}