.pagination {
    color: #A1A1A1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    .dot {
        margin: 2px 6px;
        height: 8px;
        width: 8px;
        background-color: #e6e6e6;
        border-radius: 50%;
        display: inline-block;
        &.active {
            background-color: #A1A1A1;
        }
    }
    span {
        cursor: pointer;
        margin: 0px 8px;
        svg {
            margin: 3px 4px;
            width: 12px;
            height: 12px;
            display: inline-block;
            fill: #A1A1A1;
            color: #A1A1A1;
        }
    }
}