.map-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.mapboxgl-ctrl-directions {
  display: none;
}

.map-pin {
  span {
    height: 15px;
    width: 30px;
    margin: 0;
    z-index: 99;
    position: absolute;
    top: 5px;
    left: 0px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
  svg {
    height: 15px;
    width: 15px;
    margin: 0;
    z-index: 99;
    position: absolute;
    top: 5px;
    left: 7px;
    path {
      fill: #fff;
      color: #fff;
    }
    &.pin {
      height: 30px;
      width: 30px;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      path {
        fill: #009842 !important;
        color: #009842 !important;
      }
    }
  }
}

.marker.highlight {
  z-index: 9999;
}
