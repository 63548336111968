.check-input {
    height: 44px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .short-title,
    .title {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        line-height: 34px;
        // vertical-align: middle;
        margin: 0 7px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .short-title {
        background: #ffffff;
        border-radius: 3px;
        width: 51px;
        max-width: 51px;
        min-width: 51px;
        text-align: center;
    }
    label.label-left {
        display: inline-block;
        line-height: 32px;
        font-size: 11px;
        font-weight: bold;
        color: #a1a1a1;
        padding: 0 22px;
        width: 132px;
    }
    .check {
        margin: 0px 27px 0px 0;
        width: 18px;
        height: 18px;
        background: transparent;
        border-radius: 5px;
        border: 1px #b3b3b3 solid;
        display: inline-block;
        svg {
            width: 12px;
            height: 12px;
            display: block;
            opacity: 0;
            margin: 3px;
            // margin: 0 10px 0 0;
            path {
                fill: #ffffff;
                color: #ffffff;
            }
        }
        &:hover {
            cursor: pointer;
            background: #a1a1a1;
            border: 0;
            svg {
                opacity: 1;
            }
        }
    }
    input[type="checkbox"] {
        visibility: hidden;
        display: none;
        &:checked + .check {
            background: #009942;
            border: 0;
            svg {
                opacity: 1;
            }
        }
    }
    &.readOnly {
        pointer-events: none !important;
        .check {
            pointer-events: none !important;
        }
        label {
            background: rgb(220, 220, 220) !important;
        }
    }
}
