.customer-groups.container-inner {
  height: calc(100%);
  padding: 32px 32px 0 32px;
  .page-content {
    height: calc(100% - 92px);
  }
  .map {
    height: 100%;
  }

  .row-no-entries {

    font-size: 11px;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
    color: #a1a1a1;
    font-weight: 700;
    padding: 0;
    margin: 0;

    .col {
      padding: 0;
      margin: 0;
    }

  }

}