@import "custom_bootstrap.scss";
@import "~bootstrap/scss/bootstrap"; // importing a css file from the bootstrap node module
@import "~antd/dist/antd.css";

// custom style for body
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

p,
label {
  padding: 0;
  margin: 0;
}

.ant-btn-primary {
  background-color: #009842 !important;
  border-color: #009842 !important;
}

.ant-notification {
  z-index: 1050;
}

.ant-message {
  z-index: 1050;
}

.ReactModal__Overlay {
  z-index: 1040;
}

.ant-switch-checked {
  background-color: #009842;
}

.ant-input-group.search,
.ant-input-group.search input {
  .ant-input-affix-wrapper {
    padding-top: 0;
  }
  .ant-dropdown-trigger {
    svg {
      margin-top: 4px !important;
      fill: #a1a1a1;
      color: #a1a1a1;
    }
    span {
      position: relative;
      top: -6px;
    }
  }
  font-size: 1rem;
  font-weight: 400;
}

.ant-steps-icon {
  svg {
    position: relative;
    top: -7px;
  }
}

.ant-modal-body:has(> div.bg-gray) {
  background-color: rgb(240, 240, 240);
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}