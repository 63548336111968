:root {
    --stripe-size: 6px;
    --color1: #6c757d;
    --color2: #313131;
    --duration: 1s;
  }

@keyframes stripeTransform {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(var(--stripe-size) * -1));
    }
}

@keyframes stripeBackgroundPosition {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: calc(var(--stripe-size) * -1) 0;
    }
}

.api-status {
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    .api-status-circle {
        border-radius: 50%;
        display: inline-block;
        background: #6c757d !important;
        margin: 0px 4px;
        overflow: hidden;
    }
    &.api-status-error {
        .api-status-circle {
            background: #ff8a8a !important;
        }
    }
    &.api-status-success {
        .api-status-circle {
            background: #009842 !important;
        }
    }
}

.stripe {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &_inner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 8rem;
        text-align: center;
        font-family: "Anton", sans-serif;
        color: rgba(#fff, 0);
        background: repeating-linear-gradient(
            315deg,
            var(--color1) 25%,
            var(--color1) 50%,
            var(--color2) 50%,
            var(--color2) 75%
        );
        background-size: var(--stripe-size) var(--stripe-size);
        background-clip: text;
        animation: stripeBackgroundPosition var(--duration) linear infinite;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + var(--stripe-size));
        height: 100%;
        background: repeating-linear-gradient(
            315deg,
            var(--color2) 25%,
            var(--color2) 50%,
            var(--color1) 50%,
            var(--color1) 75%
        );
        background-size: var(--stripe-size) var(--stripe-size);
        animation: stripeTransform var(--duration) linear infinite;
        animation-direction: reverse;
    }

}
