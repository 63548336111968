.modal {
  display: block;
  position: relative;
  .modal-dialog {
    margin: 0;
    max-width: 99999px;
    .modal-content {
      border: 0;
      .scroll {
        max-height: 250px;
        overflow-y: scroll;
      }
      .row {
        margin-top: 11px;
        margin-bottom: 11px;
        select,
        input[type="text"] {
          width: 100%;
          max-width: 286px;
        }
        input[type="checkbox"] {
          width: 18px;
          width: 18px;
          max-width: 286px;
        }
      }
    }
  }
}

.table-filter-modal {
  input[type="radio"] {
    margin-right: 0.5em;
  }
  select,
  input[type="text"] {
    display: inline-block;
    border: 0;
    // border-bottom: 1px solid #ccc;
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    margin: 4px;
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .row {
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0 25%;
    }
    .separator::before,
    .separator::after {
      content: "";
      flex: 1;
      border-bottom: 1px dashed #ccc;
    }
    .separator::before {
      margin-right: 0.25em;
    }
    .separator::after {
      margin-left: 0.25em;
    }
    &:last-child {
      .separator,
      .separator::before,
      .separator::after {
        display: none;
      }
    }
  }
}
