$map-unit: 180px;
$map-unit-input: 54px;
$map-unit-header: 78px;
.map-input {
    z-index: 999;
    position: relative;
    // width: calc(100% - 30px);
    width: 100%;
    height: 2 * $map-unit + $map-unit-header;
    background: #ffffff;
    border: 0 !important;
    border-radius: 3px;
    // overflow: hidden;
    margin: 4px 0 4px 0;
    // margin-left: 15px;
    .map-holder {
        height: 2 * $map-unit + $map-unit-header - $map-unit-input;
    }
    &.small {
        height: 1.5 * $map-unit;
        .map-holder {
            height: 1.5 * $map-unit - $map-unit-input;
        }
    }
    .suggestions-holder {
        z-index: 999;
        background: #fff;
        margin: 0 0 0 125px;
        max-width: 440px;
        border: 1px solid rgb(204, 204, 204);
        border-top: 0;
        height: 190px;
        // max-width: calc(100% - 96px);
        ul {
            padding: 0;
            background: #fff;
            li {
                background: #fff;
                text-decoration: none;
                list-style: none;
                padding: 8px 12px 8px 5px;
                border-bottom: 1px solid #ccc;
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 22px;
                font-weight: normal;
                color: #009842;
                cursor: pointer;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    .input-holder {
        height: 43px;
        background: #fff;
        border-radius: 3px;
        padding: 11px 0 11px 22px;
        // margin: 0 0 9px 0;
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 22px;
        font-weight: normal;
        color: #000;
        label {
            display: inline-block;
            line-height: 32px;
            font-size: 11px;
            font-weight: bold;
            color: #a1a1a1;
            margin-right: 22px;
            width: 88px;
            &.invalid {
                color: #e44;
            }
        }
        input {
            display: inline-block;
            background: #ffffff;
            height: 32px;
            width: 100%;
            max-width: calc(100% - 162px);
            border: 0 !important;
            outline: none;
            padding: 0;
            &:disabled,
            &[disabled] {
                color: #000000;
            }
        }
        .actions {
            position: absolute;
            right: 16px;
            bottom: 14px;
            display: inline-block;
            height: 24px;
            button {
                background: transparent;
                border: 0;
                padding: 0;
                svg {
                    width: 16px;
                    height: 16px;
                    margin: 0 12px;
                    display: inline-block;
                    cursor: pointer;
                }
                &.edit svg,
                &.save svg {
                    fill: #009842;
                    color: #009842;
                }
                &:disabled svg,
                &[disabled] svg {
                    cursor: not-allowed;
                    fill: #a1a1a1;
                    color: #a1a1a1;
                    text-decoration: none;
                    pointer-events: none;
                }
                &.cancel svg {
                    fill: #e44;
                    color: #e44;
                }
            }
        }
    }
}
