$transition: all 0.1s;

.modal {
  display: block;
  position: relative;
  .modal-dialog {
    margin: 0;
    max-width: 99999px;
    .modal-content {
      border: 0;
      .scroll {
        max-height: 250px;
        overflow-y: scroll;
      }
      .row {
        margin-top: 11px;
        margin-bottom: 11px;
        select,
        input[type="text"] {
          width: 100%;
          max-width: 286px;
        }
        input[type="checkbox"] {
          width: 18px;
          width: 18px;
          max-width: 286px;
        }
      }
    }
  }
}

.ReactModal__Content {
  overflow: visible !important;
}

.tours {
  .tour-shortcut {
    div {
      float: right;
      span {
        display: inline-block;
        font-size: 12px;
        line-height: 22px;
        margin-right: 12px;
      }
      input {
        display: inline-block;
        border: 0;
        border-bottom: #000 solid 1px;
        background: transparent;
        font-size: 12px;
        line-height: 12px;
        width: 44px;
        margin-top: -4px;
      }
    }
  }

  .mapboxgl-ctrl,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .meta-alert {
    color: #ff8a8a !important;
  }

  a:hover {
    text-decoration: none !important;
  }

  .max-height-600 {
    max-height: 600px;
    padding-top: 60px;
  }

  &.container-inner {
    .table-divider {
      padding: 14px 0px;
    }
    .comaparison-input {
      border-radius: 3px;
      overflow: hidden;
      input {
        display: block;
        padding: 4px 0;
        font-size: 24px;
        border: 0;
        text-align: center;
        width: 100%;
        background: #e6e6e6;
        color: #000;
        &:disabled,
        &[disabled] {
          color: #bcbcbc;
        }
      }
      &.success {
        .diff-col input {
          background: #64c464 !important;
          color: #42a441 !important;
        }
      }
      &.danger {
        .diff-col input {
          background: #ff8a8a !important;
          color: #ff4d4d !important;
        }
      }
      label {
        display: block;
        padding: 4px 0;
        font-size: 12px;
        border: 0;
        text-align: center;
        width: 100%;
        background: #fff;
        color: #000;
        font-weight: normal;
        span {
          margin: 0 4px;
          padding: 2px 4px;
          cursor: pointer;
        }
      }
    }

    .tour-location-response-selection {
      overflow: visible;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.readOnly {
        pointer-events: none !important;
        .check {
          pointer-events: none !important;
        }
      }
      p {
        display: inline-block;
      }
      .check {
        margin: 0px 27px 0px 0;
        width: 18px;
        height: 18px;
        background: transparent;
        border-radius: 5px;
        border: 1px #b3b3b3 solid;
        display: inline-block;
        svg {
          width: 12px;
          height: 12px;
          display: block;
          opacity: 0;
          margin: 3px;
          path {
            fill: #ffffff;
            color: #ffffff;
          }
        }

        &.disabled {
          background: #e6e6e6;
        }
        &:hover:not(.disabled) {
          cursor: pointer;
          background: #009942;
          border: 0;
          svg {
            opacity: 1;
          }
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        display: none;
        &:checked + .check {
          background: #009942;
          border: 0;
          svg {
            opacity: 1;
          }
        }
      }
    }
    .tour {
      background-color: #ffffff;
      border-radius: 3px;
      overflow: hidden;
      // height: 138px;
      margin: 0 0 16px 0;
      position: relative;
      padding: 20px 26px 18px 26px;
      > .row {
        margin-right: -16px;
        margin-left: -16px;
        > .col {
          padding-right: 16px;
          padding-left: 16px;
        }
      }
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        fill: #000000;
        color: #000000;
        margin: 0 12px 0 0;
        &.small {
          margin: 0 16px 0 0;
          width: 16px;
          height: 16px;
        }
      }
      .header {
        display: inline-block;
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0px;
        padding: 0 0 6px 0;
        text-decoration: none !important;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 32px;
        li {
          text-decoration: none !important;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0px;
          line-height: 14px;
          white-space: nowrap;
          padding: 0 0 6px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          &:last-child {
            padding-bottom: 0;
          }
          &.more {
            color: #a1a1a1;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
          }
        }
        &.small {
          li {
            color: #a1a1a1;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            padding: 0 0 4px 0;
            &:first {
              padding: 0 0 6px 0;
            }
          }
        }
      }
      &:before {
        content: "";
        height: 100%;
        width: 7px;
        background: #e6e6e6;
        position: absolute;
        top: 0;
        left: 0;
      }
      &.status-complete {
        &:before {
          background: #009842;
        }
        .status-text {
          color: #009842;
        }
      }
      &.status-scheduled {
        &:before {
          background: #64c464;
        }
        .status-text {
          color: #64c464;
        }
      }
      &.status-waiting {
        &:before {
          background: #ffc34d;
        }
        .status-text {
          color: #ffc34d;
        }
      }
      &.status-error {
        &:before {
          background: #ff4d4d;
        }
        .status-text {
          color: #ff4d4d;
        }
      }
    }
    .route-step {
      z-index: 0;
      background-color: #ffffff;
      border-radius: 3px;
      width: 100%;
      padding: 20px 20px 20px 58px;
      margin: 12px 0;
      position: relative;
      height: 68px;
      display: flex;
      justify-content: space-between;

      &.route-step-danger {
        background-color: #ff8a8a !important;
        p.address {
          color: #000 !important;
        }
        svg,
        svg path {
          fill: #000 !important;
          color: #000 !important;
        }
        .date {
          color: #000 !important;
        }
      }

      &.route-step-add {
        background-color: rgba(255, 255, 255, 0);
        border: 2px dashed #a1a1a1;
        // cursor: pointer;
        padding: 5px 20px 5px 58px;
        .route-step-content {
          margin: 0;
          .row {
            height: 100%;
          }
        }
        svg {
          height: 18px;
          width: 18px;
          margin: auto;
          path {
            fill: #a1a1a1;
            color: #a1a1a1;
          }
        }
      }

      .route-step-icon {
        position: absolute;
        top: 22px;
        left: 20px;
        svg {
          height: 18px;
          width: 18px;
          margin: auto 20px auto 0;
          path {
            fill: #000;
            color: #000;
          }
        }
      }
      .route-step-content {
        margin: auto 0;
        padding: 0 32px 0 0;
        flex: 1 1 auto;
        overflow: hidden;
        svg.comment {
          height: 18px;
          width: 18px;
          margin: auto;
          path,
          g {
            fill: #a1a1a1;
            color: #a1a1a1;
          }
          &:hover {
            path,
            g {
              fill: #009842;
              color: #009842;
            }
          }
        }
        .col {
          padding-right: 12px;
          padding-left: 12px;
          &:first-child {
            padding-left: 36px;
          }
          &:last-child {
            padding-right: 18px;
          }
          &.col-input {
            p,
            input {
              display: inline-block;
            }
            p {
              margin-right: 8px;
              line-height: 35px;
              font-size: 12px;
            }
            input {
              max-width: 88px;
              margin-left: 8px;
              padding: 4px 0;
              font-size: 18px;
              border: 0;
              text-align: center;
              width: 100%;
              background: #e6e6e6;
              color: #000;
            }
          }
        }
        p {
          &.shared {
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
          }
          &.name {
            color: #000000;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 14px;
            padding: 0 0 1px 0;
            white-space: nowrap;
            overflow: hidden;
          }
          &.address {
            color: #5e5e5e;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 12px;
            padding: 1px 0 0 0;
          }
          &.date {
            color: #009842;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 12px;
            padding: 1px 0 0 0;
          }
          &.operatingExpense {
            color: #5e5e5e;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 12px;
            padding: 1px 0 0 29px;
          }
          &.missing {
            color: #ee4444;
          }
          &.full-height {
            line-height: 28px;
            font-size: 12px;
          }
          svg {
            margin: 0 6px;
            height: 18px;
            width: 18px;
            path,
            g {
              fill: #009842;
              color: #009842;
            }
          }
        }
      }
      .route-step-actions {
        margin: auto 0;
        // background: red;
        padding: 0;
        width: 180px;
        min-width: 180px;
        &.extra {
          width: 380px;
          min-width: 380px;
        }
        .route-step-action {
          float: right;
          display: inline-block;
          margin: 0 8px;
          padding: 1px 5px 2px;
          border: 0;
          background: transparent;
          box-shadow: none;
          cursor: pointer;
          &.route-step-action-100 {
          }
          &.sort {
            cursor: move;
          }
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
          }
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
          svg {
            height: 24px;
            width: 24px;
            margin: 0 12px 1px 6px;
            &.edit-icon {
              height: 18px;
              width: 18px;
              margin: 0 18px 1px 0px;
            }
            path,
            g {
              fill: #a1a1a1;
              color: #a1a1a1;
            }
            &:hover {
              path,
              g {
                fill: #009842;
                color: #009842;
              }
            }
          }
          &.route-step-action-feedback {
            svg {
              height: 20px;
              width: 20px;
            }
          }
          &.success {
            color: #009842;
            svg {
              height: 24px;
              width: 24px;
            }
          }
          &.danger {
            color: #e44;
            svg {
              height: 24px;
              width: 24px;
            }
          }
          &.disabled,
          &:disabled {
            fill: #a1a1a1 !important;
            color: #a1a1a1 !important;
            cursor: not-allowed;
            svg {
              fill: #a1a1a1 !important;
              color: #a1a1a1 !important;
            }
          }
        }
      }

      .no-location {
        &.route-step-actions,
        input,
        p {
          opacity: 0.3;
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}

.tour-changeweek-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #009842;
  border-color: #009842;
}
