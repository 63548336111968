.mapboxgl-ctrl-logo {
  display: none !important;
}

.location.container-inner {
  height: calc(100%);
  padding: 32px 32px 0 32px;

  .delete,
  .add {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0px;
    font-weight: normal;
    color: #a1a1a1;
    cursor: pointer;
    margin: 0 24px 0 0;
    float: right !important;
    svg {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin: 0 12px 3px 0;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed !important;
      text-decoration: none !important;
      pointer-events: none !important;
      color: #a1a1a1 !important;
      svg {
        fill: #a1a1a1 !important;
        color: #a1a1a1 !important;
      }
    }
  }
  .add {
    color: #009842;
    svg {
      fill: #009842;
      color: #009842;
    }
  }
  .delete svg {
    fill: #a1a1a1;
    color: #a1a1a1;
  }

  .direct-input {
    .ant-picker {
      width: calc(100% - 80px) !important;
      border: 0;
      // border-bottom: 1px solid #a1a1a1 !important;
      input {
        max-width: 100% !important;
      }
    }
  }

  .page-content {
    height: calc(100% - 92px);
  }
  .page-content.editing .tasks-row > div > div:not(.editing) {
    color: #a1a1a1;
    opacity: 0.2;
    pointer-events: none;
  }
  .map {
    height: 100%;
  }
  .tasks-row {
    background: #fff;
    border-radius: 3px;
    padding: 16px 0;
    margin: 8px 0;
    .col {
      padding: 0;
      &:first-child {
        padding-left: 18px;
      }
    }
    .due-readonly {
      margin: 4px 0 4px 0;
      span {
        font-size: 12px;
        line-height: 32px;
      }
      label {
        display: inline-block;
        line-height: 32px;
        font-size: 11px;
        font-weight: bold;
        color: #a1a1a1;
        padding: 0 22px;
        width: 80px;
      }
    }
    .direct-input,
    .editable-input {
      padding: 0;
      input {
        max-width: calc(100% - 175px);
      }
      &.readOnly {
        input {
          border-bottom: none !important;
        }
      }
      &.highlight {
        input {
          font-weight: bold;
        }
      }
      &:not(.readOnly) {
        input {
          border-bottom: 1px solid #a1a1a1 !important;
        }
      }
      label {
        width: 80px;
      }
      .actions {
        top: 6px;
      }
    }
    p {
      line-height: 20px;
      font-size: 12px;
    }
  }
  // .tasks {
  //   display: flex;
  //   max-width: 300px;
  //   .task {
  //     flex-grow: 1;
  //     margin: 0 2px 0 2px;
  //     &:first-child {
  //       margin: 0 2px 0 0px;
  //     }
  //     &:last-child {
  //       margin: 0 0px 0 2px;
  //     }
  //     color: #a1a1a1;
  //     &.task-done {
  //       color: #000;
  //     }
  //   }
  // }
  .tasks,
  .skills {
    display: flex;
    max-width: 300px;
    .task,
    .skill {
      flex-grow: 1;
      margin: 0 2px 0 2px;
      &:first-child {
        margin: 0 2px 0 0px;
      }
      &:last-child {
        margin: 0 0px 0 2px;
      }
      color: #a1a1a1;
      &.task-done {
        color: #000;
      }
      &.skill-required {
        color: #000;
      }
    }
    .task.col,
    .skill.col {
      padding-right: 0 !important;
      padding-left: 0 !important;
      overflow: hidden !important;
      min-width: 30px !important;
      max-width: 30px !important;
      letter-spacing: -1px;
      font-size: 10px;
      margin: 0;
    }
  }
  .tasks,
  .skills {
    margin-right: 0px;
    margin-left: 0px;
    justify-content: left;
  }
  .table-divider .action {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0px;
    font-weight: normal;
    color: #a1a1a1;
    cursor: pointer;
    margin: 0 24px 0 0;
    svg {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin: 0 12px 3px 0;
      fill: #a1a1a1;
      color: #a1a1a1;
    }
  }
}

@media (min-width: 1200px) {
  .col-xl-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
