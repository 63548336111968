@media print {
  @page {
    size: landscape;
    margin: 18mm;
  }
}

.print-planed-locations {

  h3  {
    margin: 4px 0;
  }

   h6 {
    margin: 4px 0 16px 0;
  }

  .item {
    border: #a1a1a1 solid 1px;
    margin: 1mm;
    padding: 1mm 2.5mm;
  }

  .big {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .bold {
    font-weight: 600;
    .col {
      padding-bottom: 0 !important;
    }
  }

  .bolder {
    font-weight: 800;
  }

  span {
    line-height: 12px;
  }

  .small {
    font-size: 9px;  
    letter-spacing: -.5px;
  }

  font-size: 10px;
  line-height: 10px;
  .row {
    .col {
      padding: 0.4mm 0.4mm 0.8mm 0.4mm;
      &:first-child {
        padding: 0.4mm 4px 0.8mm 36px;
      }
      &:last-child {
        padding: 0.4mm 36px 0.8mm 4px;
      }
      // .row .col {
      //   &:first-child {
      //     padding: 0.4mm 4px 0.8mm 8px;
      //   }
      // }
    }
  }
}

.print-planing-locations-action {
  opacity: 0;
}
