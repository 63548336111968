.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
  padding: 16px;
  .steps-content-running p {
    text-align: left;
    font-size: 14px;
  }
}

.import-row {
  text-align: left !important;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #009842;
}

.ant-progress-status-success .ant-progress-text {
  color: #009842;
}


.steps-action {
  margin-top: 18px;
  margin-right: 12px;
  button {
    float: left;
  }
}

@keyframes rotation {
  from {
    transform: translateX(-16px);
  }
  to {
    transform: translateX(100px);
  }
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #009842;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #009842;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #009842;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #009842;
}

.ant-upload {
  width: 100%;
  max-width: 480px;
  margin: auto;
}

.import.container-inner {

  .col .box {
    background: #fff;
    border-radius: 3px;
    padding: 16px;
    // margin: 9px 0;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
    color: #000;
  }

  button:not(.ant-btn) {
    display: block;
    letter-spacing: 0;
    padding: 13px 0;
    border: 0;
    &:not(.btn-link) {
      text-align: center;
      background-color: #009842;
      border-radius: 5px;
      box-shadow: 6px 5px 20px 0 hsla(0,0%,54.9%,.25);
      overflow: visible;
      width: 100%;
      max-width: 210px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      margin: 16px 16px 0 0;
    }
    &.btn-link {
      float: left;
      text-align: left;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      color: #009842;
      margin: 0 8px 0 0;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      text-decoration: none;
      pointer-events: none;
      background-color: #a1a1a1;
      svg {
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
  }

  svg.rc-progress-line {
    border-radius: 50px;
    &.loading {
      .rc-progress-line-path {
        stroke-dasharray: 15px, 100px !important;
        animation: rotation 3s infinite linear;
      }
    }
  }
}
