// .offtime.container-inner {
//     height: 100%;
//     .page-content {
//         height: 100%;
//         height: 1000px;
//         min-height: 1000px;
//     }
// }

.offtime.container-inner {
    padding: 32px 0;
    .scheduler > thead td > div {
        padding-left: 32px;
    }
}

.scheduler {
    margin: 20px 0 !important;
    border-spacing: 0;
    // .scheduler-content-table,
    // .resource-table {
    //     tr {
    //         &:nth-child(even) {
    //             background-color: #f7f7f7;
    //         }
    //         &:nth-child(odd) {
    //             background-color: #f1f1f1;
    //         }
    //     }
    // }
}

// .react-calendar-timeline {
//     div {
//         border: 0;
//         border-top: 0;
//         border-left: 0;
//         border-bottom: 0;
//         border-right: 0;
//     }
//     .rct-header-root {
//         background: #ffffff;
//         border-bottom: 1px solid #e6e6e6;
//     }
//     .rct-calendar-header {
//         background: #ffffff;
//         border: 0;
//     }
//     .rct-sidebar {
//         border-right: 0;
//         .rct-sidebar-row {
//             background: #ffffff;
//             padding-left: 20px;
//             color: #000000;
//             font-size: 12px;
//             font-weight: 700;
//             letter-spacing: 0px;
//             line-height: 1.2;
//             text-align: left;
//             border-bottom: 1px solid #e6e6e6;
//             &.rct-sidebar-row-odd,
//             &.rct-sidebar-row-even {
//                 background: #ffffff;
//             }
//         }
//     }
//     .rct-vertical-lines .rct-vl {
//         border-left: 1px solid #e6e6e6;
//         &.rct-day-6,
//         &.rct-day-0 {
//             background: transparent;
//         }
//         &.rct-vl-first {
//             border-left-width: 1px;
//         }
//     }
//     .rct-scroll {
//         .rct-horizontal-lines {
//             .rct-hl-even,
//             .rct-hl-odd {
//                 background: #ffffff;
//                 border-bottom: 1px solid #e6e6e6;
//             }
//         }
//     }
//     .rct-item {
//         overflow: hidden;
//         .rct-item-handler-resize-left,
//         .rct-item-handler-resize-right {
//             background: #009842;
//             cursor: col-resize;
//         }
//     }
//     .rct-calendar-header-interval-wrapper {
//         margin: 8px 0 0 0;
//         .rct-calendar-header-interval {
//             padding: 0 6px;
//             line-height: 16px;
//             font-size: 12px;
//             letter-spacing: 0px;
//             text-align: left;
//             &.rct-calendar-header-interval-top {
//                 color: #000000;
//                 font-weight: 700;
//                 vertical-align: bottom;
//                 padding: 0 8px;
//             }
//             &.rct-calendar-header-interval-bottom {
//                 border-left: 1px solid #e6e6e6;
//                 // border-right: 1px solid #e6e6e6;
//                 color: #a1a1a1;
//                 font-weight: 400;
//                 padding: 0 6px 2px 6px;
//                 overflow: hidden;
//             }
//         }
//     }
// }
