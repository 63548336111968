.date-was-fixed {
  color: #9f5afd !important;
}

.foresightedMonthCount[disabled] {
  color: #a1a1a1;
}

.name-with-planed-date {
  display: block;
  // white-space: nowrap;
  width: 100%;
  color: #004298;
}

.tooltip-container {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 3px;
  z-index: 9999;
}

.loading-top-center {
  text-align: center;
  position: absolute;
  top: 40px;
  left: calc(50% - 40px);
  cursor: wait;
}

.planning-stepper-content {
  position: relative;
}

.planning-stepper-content .location .page-header .col {
  padding-right: 0;
}

.planning-wrapper .planning-stepper-content .container-inner.complete .result > .col p  {
  display: inline-block;
  cursor: pointer;
  color: #009842;
  svg {
    margin: 0 6px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    fill: #009842;
    color: #009842;
  }
}

.planning-wrapper {
  position: relative;
  height: calc(100%);

  .table .table-row .table-row-inner .col {
    padding-right: 8px;
    // padding-left: 12px;
  }

  .col-trip {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .col.col-allNextJobDate a span {
    color: #004298 !important;
  }

  .col.col-duePrice a span,
  .col.col-operatingExpense a span {
    display: block;
    width: 100%;
    color: #a1a1a1;
    &.hidden {
      display: none;
    }
    &.highlight {
      color: #cda223 !important;
    }
    &.warning {
      color: rgb(159, 90, 253) !important;
    }
    &.danger {
      color: rgb(255, 77, 77) !important;
    }
  }
  .clickable {
    cursor: pointer;
  }
  .foresighted-months {
    text-align: right;
    p {
      display: inline-block;
      font-size: 12px;
      line-height: 22px;
      margin-right: 12px;
    }
    input {
      display: inline-block;
      border: 0;
      border-bottom: #000 solid 1px;
      background: transparent;
      font-size: 12px;
      line-height: 12px;
      width: 58px;
      margin-top: -4px;
    }
    button {
      padding: 2px;
      border: 0;
      background-color: transparent;
      svg {
        margin: 0 6px 3px 6px;
        width: 12px;
        height: 12px;
        fill: #000;
        color: #000;
        cursor: pointer;
        &.disabled {
          cursor: default;
          pointer-events: none;
          path,
          g {
            fill: #a1a1a1 !important;
            color: #a1a1a1 !important;
          }
        }
      }
      &:hover {
        svg {
          fill: #009842;
          color: #009842;
        }
      }
    }
  }
  .page-subtitle {
    span {
      display: inline-block;
      &.skill-bubble {
        background-color: #ffffff;
        border-radius: 3px;
        color: #009842;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 14px;
        padding: 8px;
        margin: 0 0 0 16px;
        min-width: 51px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .datepicker {
    .trigger {
      width: 184px;
      margin: 0 auto;
      display: block;
    }
  }
  .planning-stepper-content {
    // margin: 0 334px 0 0;
    margin: 0 0 128px 0;
    height: calc(100%);
    .map {
      padding: 28px 0 28px 0;
    }
    .container-inner {
      height: calc(100%);
      .page-content {
        height: calc(100% - 164px);
        margin-right: 0;
        &.scroll {
          overflow-y: scroll;
        }
        .table {
          // height: calc(100%);
          .table-body {
            height: calc(100% - 40px);
          }
        }
      }
      &.route {
        height: calc(100vh - 70px);
        max-height: calc(100vh - 70px);
        .datepicker {
          .tooltip-container {
            transform: translateX(-50%);
          }
        }
        .page-header-actions {
          min-height: 28px;
          svg,
          p {
            display: inline-block;
          }
          svg {
            margin: 0 6px 3px 6px;
            width: 12px;
            height: 12px;
            fill: #009842;
            color: #009842;
            cursor: pointer;
            &.disabled {
              cursor: default;
              pointer-events: none;
              path,
              g {
                fill: #a1a1a1 !important;
                color: #a1a1a1 !important;
              }
            }
          }
        }
        > .row {
          height: 100%;
          > .col {
            height: 100%;
            overflow: hidden;
            &:first-child {
              // overflow-y: scroll;
              padding: 32px 0 32px 32px;
            }
            &:last-child {
              padding: 0 16px 0 0;
            }
          }
        }
        &.route {
          padding: 0 0 0 32px;
        }
        ul {
          padding: 0;
          margin: 0;
        }
      }
      &.complete {
        padding: 180px 100px 24px 100px;
        .jumbo {
          .icon {
            display: block;
            width: 100%;
            svg {
              width: 80px;
              height: 80px;
              fill: #009842;
              color: #009842;
            }
          }
          h2.text {
            margin: 54px 0;
            display: block;
            width: 100%;
            text-align: center;
            color: #009842;
            font-size: 34px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1.2;
          }
          &.error {
            .icon {
              svg {
                fill: #ee4444;
                color: #ee4444;
              }
            }
            h2.text {
              color: #ee4444;
            }
          }
        }
        .result {
          margin-top: 120px;
          > .col {
            padding: 0 50px;
            h4 {
              color: #000;
              font-size: 18px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              display: inline-block;
            }
            p {
              display: inline-block;
              cursor: pointer;
              color: #009842;
              margin-left: 22px;
              line-height: 20px;
              font-size: 12px;
              margin-bottom: 16px;
              svg {
                margin: 0 6px;
                width: 28px;
                height: 28px;
                cursor: pointer;
                fill: #009842;
                color: #009842;
              }
            }
            .result-line {
              background-color: #ffffff;
              border-radius: 3px;
              width: 100%;
              padding: 18px;
              margin: 7px 0px;
              position: relative;
              height: 64px;
              display: flex;
              justify-content: space-between;
              p {
                overflow: hidden;
                color: #000000;
                font-size: 12px;
                font-weight: 400;
                padding: 0;
                letter-spacing: 0px;
                line-height: 28px;
                text-align: left;
                &.additional {
                  color: #a1a1a1;
                }
                svg {
                  display: inline-block;
                  margin: 0 6px 3px 2px;
                  width: 16px;
                  height: 16px;
                  fill: #a1a1a1;
                  color: #a1a1a1;
                }
                &.action {
                  cursor: pointer;
                  color: #009842;
                  svg {
                    margin: 0 6px;
                    width: 28px;
                    height: 28px;
                    cursor: pointer;
                    fill: #009842;
                    color: #009842;
                  }
                }
              }
            }
          }
        }
      }
      // &.location {
      //   position: fixed;
      //   bottom: 0;
      //   top: 70px;
      //   right: 334px;
      //   left: 0;
      //   overflow-y: scroll;
      // }
    }
  }
  .planning-stepper-header {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    // right: 0;
    // bottom: 0;
    // top: 70px;
    // width: 334px;
    padding: 12px 44px;
    flex-direction: row;
    display: flex;
    align-content: flex-start;
    align-items: top;
    justify-content: flex-end;
    flex-wrap: nowrap;
    .steps-pre {
      // border: 1px solid blue;
      height: 67px;
      flex: 0 1 auto;
      h2 {
        color: #009842;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        margin: 0 0 44px 0;
        line-height: 1.2;
        display: inline-block;
      }
      svg {
        display: inline-block;
        margin: 0 6px 4px 2px;
        width: 16px;
        height: 16px;
        fill: #009842;
        color: #009842;
        cursor: pointer;
      }
    }
    .steps {
      // border: 1px solid blue;
      flex: 8 1 auto;
      overflow: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      .steps-inner {
        display: flex;
        .step {
          flex: 1 1 auto;
          height: 100%;
          position: relative;
          padding: 0 12px;
          .step-subtext {
            display: block;
            color: #e6e6e6;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 18px;
            position: absolute;
            top: 24px;
            left: 20px;
            // bottom: 18px;
            right: 0px;
            // border-left: 1px solid #e6e6e6;
            padding: 0 0 0 40px;
            // line-height: calc(130px - 50px - 18px);
            overflow-y: visible;
            white-space: nowrap;
            .two-lines {
              // line-height: calc(130px - 50px - 60px);
              overflow: visible;
              white-space: nowrap;
            }
          }
          &.step-0 {
            height: 150px;
          }
          &:last-child {
            height: 40px;
            .step-subtext {
              // border-left: 0;
              display: none;
            }
          }
          .step-header {
            span {
              display: inline-block;
              color: #e6e6e6;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 24px;
              &.circle {
                margin-right: 24px;
                height: 24px;
                width: 24px;
                background-color: #e6e6e6;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 24px;
              }
            }
          }
          &.active {
            .step-header span {
              color: #009842;
              &.circle {
                color: #fff;
                background-color: #009842;
              }
            }
            .step-subtext {
              color: #000;
            }
          }
        }
      }
    }
    .steps-post {
      // border: 1px solid blue;
      flex: 1 1 auto;
      // height: 134px;
      // padding: 29px 0;
      // margin: 20px 0 0 0;
      // border-top: 1px solid #e6e6e6;
      .reset-all {
        width: 100%;
        text-align: center;
        padding: 4px;
        margin: 12px 0 0 0;
        cursor: pointer;
        font-size: 10px;
      }
      .button {
        display: block;
        border: 0;
        background-color: #009842;
        border-radius: 5px;
        box-shadow: 6px 5px 20px 0px rgba(140, 140, 140, 0.25);
        overflow: visible;
        width: 100%;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0px;
        margin: 0;
        padding: 13px 0;
        text-align: center;
        line-height: 18px;
        &:disabled,
        &.disabled {
          pointer-events: none;
          cursor: default;
          background-color: #a1a1a1 !important;
        }
        &.alert {
          background-color: #ffaa22;
        }
      }
    }
    .steps-info {
      // border: 1px solid blue;
      flex: 2 1 auto;
      max-width: 380px;
      // margin: 44px 0 0 0;
      padding: 0 24px;
      // align-items: end;
      // > div {
      //   flex: 1 1 auto;
      //   // max-width: 220px;
      //   // float: right;
      // }
      p {
        color: #009842;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.2;
        margin: 4px 0;
        span {
          width: 50%;
          display: inline-block;
        }
        span:first-child {
          text-align: left;
        }
        span:last-child {
          text-align: right;
        }
      }
      .steps-info-alert {
        color: #ffaa22;
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            padding: 0 4px;
          }
        }
        svg {
          margin: 0 auto 4px auto;
          width: 37px;
          height: 37px;
          display: block;
          fill: #ffaa22;
          color: #ffaa22;
        }
        p {
          color: #ffaa22;
        }
        .alert-inner {
          padding: 2px 0;
          text-align: center;
        }
      }
    }
    // .next {
    //     border: 1px solid blue;
    //     pos
    // }
    // .steps {
    //     .step {
    //         height: 150px;
    //         border: 1px solid red;
    //     }
    // }
  }
  .tasks,
  .skills {
    display: flex;
    max-width: 300px;
    .task,
    .skill {
      flex-grow: 1;
      margin: 0 2px 0 2px;
      text-transform: uppercase;
      &:first-child {
        margin: 0 2px 0 0px;
      }
      &:last-child {
        margin: 0 0px 0 2px;
      }
      color: #a1a1a1;
      &.task-done {
        color: #000;
      }
      &.skill-required {
        color: #000;
      }
    }
    .task.col,
    .skill.col {
      padding-right: 0 !important;
      padding-left: 0 !important;
      overflow: hidden !important;
      min-width: 28px !important;
      max-width: 28px !important;
      font-size: 11px !important;
      letter-spacing: -1px;
      margin: 0;
    }
  }
  .tasks,
  .skills {
    margin-right: 0px;
    margin-left: 0px;
    justify-content: left;
  }
  .due-later {
    color: #a1a1a1;
  }
  // .due-fixed {
  //   color: #1890ff;
  // }
  .due-warning {
    &.due-warning-1 {
      color: #e5b800;
    }
    &.due-warning-2 {
      color: #ffaa22;
    }
    &.due-warning-3 {
      color: #ee4444;
    }
  }
  .due-unnecessary {
    // color: #e6e6e6 !important;
    visibility: hidden;
  }
  // .due-fixed {
  //   // color: #e6e6e6 !important;
  //   visibility: hidden;
  // }
  &.tooltip-container {
    width: 174px;
    height: 107px;
    background-color: #ffffff;
    border-radius: 3px;
    border: 0;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    overflow: visible;
    padding: 12px 14px;
    p {
      color: #000000;
      font-size: 12px;
      font-weight: 400;
      margin: 8px 6px;
      letter-spacing: 0px;
      line-height: 14px;
      span {
        color: #009842;
        font-weight: 700;
        min-width: 50px;
        display: inline-block;
      }
      &.title {
        margin: 9px 6px 22px 6px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
      }
    }
    .delete,
    .save {
      cursor: pointer;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.2;
      text-align: right;
      svg {
        width: 14px;
        height: 14px;
        display: inline-block;
        margin: 0 10px 3px 0;
      }
    }
    .delete {
      color: #a1a1a1;
      svg {
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
    .save {
      color: #009842;
      svg {
        fill: #009842;
        color: #009842;
      }
    }
    .close {
      margin: 0;
      text-align: right;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
        display: inline-block;
        margin: 0 -5px 3px 0;
        fill: #a1a1a1;
        color: #a1a1a1;
      }
    }
  }
}

li.tour-item {
  list-style: none !important;
  .leg-info {
    border-left: 1px #a1a1a1 dashed;
    width: 100%;
    margin: 12px 0 12px 24px;
    font-size: 12px;
    .col.col-loading {
      height: 36px;
      padding: 6px 24px;
    }
  }

  .route-step {
    z-index: 1;
    background-color: #ffffff;
    border-radius: 3px;
    width: 100%;
    padding: 20px 20px 20px 58px;
    margin: 12px 0;
    position: relative;
    height: 68px;
    display: flex;
    justify-content: space-between;
    &.route-step-type-2 {
      background: #99f;
      color: #f2f2f2 !important;
      p {
        color: #f2f2f2 !important;
      }
      .route-step-icon svg path,
      .route-step-actions .route-step-action svg path,
      .route-step-actions .route-step-action svg g {
        fill: #f2f2f2;
        color: #f2f2f2;
      }
    }
    &.failure-fixedDate {
      background: rgb(255, 177, 153);
      color: #f2f2f2 !important;
      p {
        color: #f2f2f2 !important;
      }
      .route-step-icon svg path,
      .route-step-actions .route-step-action svg path,
      .route-step-actions .route-step-action svg g {
        fill: #f2f2f2;
        color: #f2f2f2;
      }
    }
    &.failure-location {
      background: #ffc34d;
      color: #f2f2f2 !important;
      p {
        color: #f2f2f2 !important;
      }
      .route-step-icon svg path,
      .route-step-actions .route-step-action svg path,
      .route-step-actions .route-step-action svg g {
        fill: #f2f2f2;
        color: #f2f2f2;
      }
    }
    &.failure-technician {
      background: #ff4d4d;
      color: #f2f2f2 !important;
      p {
        color: #f2f2f2 !important;
      }
      .route-step-icon svg path,
      .route-step-actions .route-step-action svg path,
      .route-step-actions .route-step-action svg g {
        fill: #f2f2f2;
        color: #f2f2f2;
      }
    }
    &.failure-location.failure-technician {
      background-image: linear-gradient(
        135deg,
        #ffc34d 25%,
        #ff4d4d 25%,
        #ff4d4d 50%,
        #ffc34d 50%,
        #ffc34d 75%,
        #ff4d4d 75%,
        #ff4d4d 100%
      );
      background-size: 40px 40px;
      color: #000 !important;
      p {
        color: #000 !important;
      }
    }
    .route-step-icon {
      position: absolute;
      top: 22px;
      left: 20px;
      p {
        display: inline-block;
        margin: auto 10px auto 0;
        line-height: 18px;
        font-size: 18px;
        font-weight: bold;
        width: 18px;
      }
      svg {
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: auto 10px auto 0;
        path {
          fill: #000;
          color: #000;
        }
      }
    }
    .route-step-content {
      margin: auto 0;
      padding: 0 32px 0 0;
      flex: 1 1 auto;
      overflow: hidden;
      col {
        padding-right: 8px;
        padding-left: 8px;
        &:first-child {
          padding-left: 36px;
        }
        &:last-child {
          padding-right: 36px;
        }
      }
      p {
        &.name {
          color: #000000;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 14px;
          padding: 0 0 1px 0;
          white-space: nowrap;
          overflow: hidden;
        }
        &.address {
          color: #a1a1a1;
          font-size: 10px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 12px;
          padding: 1px 0 0 0;
        }
        &.date {
          color: #009842;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 12px;
          padding: 1px 0 0 0;
        }
        &.operatingExpenses {
          color: #a1a1a1;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 12px;
          padding: 1px 0 0 0;
        }
        &.missing {
          color: #ee4444;
        }
      }
    }
    .route-step-actions {
      margin: auto 0;
      // background: red;
      padding: 0;
      width: 336px;
      min-width: 336px;
      .route-step-action {
        float: right;
        display: inline-block;
        margin: 0 8px;
        padding: 1px 5px 2px;
        border: 0;
        background: transparent;
        box-shadow: none;
        max-width: 27px;
        cursor: pointer;
        &.sort {
          cursor: move;
        }
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-left: 0;
        }
        &.hidden {
          opacity: 0;
          pointer-events: none;
        }
        &.has-comment {
          svg {
            path,
            g {
              fill: #009842;
              color: #009842;
            }
          }
        }
        &.has-note {
          svg {
            g,
            path {
              fill: #00662c;
              color: #00662c;
            }
            path.st0 {
              fill: #00662c;
              color: #00662c;
            }
            path.st1 {
              fill: #33aa67;
              color: #33aa67;
            }
            path.st2 {
              fill: #009842;
              color: #009842;
            }
          }
        }
        svg {
          height: 18px;
          width: 18px;
          margin: auto;
          path,
          g {
            fill: #a1a1a1;
            color: #a1a1a1;
          }
          &:hover {
            path,
            g {
              fill: #009842;
              color: #009842;
            }
          }
        }
      }
    }
  }
}

.locations {
  .page-header-actions {
    min-height: 28px;
  }
  .row.page-content {
    margin: 0 -52px 0 0 !important;
    padding: 0;
    > .col {
      height: 100%;
      margin: 0;
      padding: 0;
      &:first-child {
        padding: 0 32px 0 0;
      }
    }
    .col.map {
      margin: 0;
      height: calc(100% - 76px);
      background-color: #fff;
      padding: 0;
      background-color: rgb(255, 255, 255);
      border-radius: 3px;
      border: 2px solid rgb(203, 199, 215);
      opacity: 1;
    }
  }
}
