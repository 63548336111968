.search {
  padding: 0 !important;
  border: none;
  width: 250px !important;
  margin: 0 24px 0 0;
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border: 0;
    box-shadow: none;
  }
  svg {
    width: 14px;
    height: 14px;
    fill: #a1a1a1;
    color: #a1a1a1;
  }
  .ant-dropdown-trigger {
    height: 30px;
    span,
    svg {
      display: inline-block;
      // &.fuzzy {
      //   fill: #009842;
      //   color: #009842;
      // }
    }
  }
  .ant-input-affix-wrapper {
    border: 0;
    background: transparent;
    color: #000;
    width: calc(100% - 40px);
  }
  input {
    border: 0;
    background: transparent;
    color: #000;

    &::placeholder {
      color: #a1a1a1;
    }
  }
  .ant-input:focus {
    border-bottom: 1px solid #e6e6e6;
  }
}
